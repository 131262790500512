// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.slide {
  position: relative;
  z-index: 1;
  a {
    // position: absolute;
    z-index: 2;
    top: 80%;
    right: 10%;
    margin-top: 0;
  }
  .yellow-btn:hover {
    background-color: transparent;
  }
}

.main-h1-container {
  display: flex;
  padding: 10px 0;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: #fff;

  p {
    text-align: left;
    margin: 0px;
    padding-left: 15px;
    @include font-size(2.2);
  }
}

.main-h1 {
  display: inline-block;
  position: relative;
  padding-bottom: 10px;
  @include font-size(3.5);

  &:before {
    position: absolute;
    content: "";
    width: 168px;
    height: 9px;
    background-color: $yellow;
    left: 50%;
    transform: translateX(-84px);
    bottom: 0;
  }
}

.main-descr-section {
  text-align: center;
  background-color: $grey-content;
  @media screen and (max-width: 850px) {
    margin-top: 100px;
  }
  @media screen and (max-width: 550px) {
    margin-top: 150px;
  }
  .main-descr {
    padding: 40px 60px;

    strong {
      font-weight: 700 !important;
    }
  }
}

.main-content {
  font-weight: 300;

  h3 {
    @include font-size(2.4);
    font-weight: 300;
    line-height: 30px;
  }

  p {
    @include font-size(1.8);
  }
}

.our-business {
  border-bottom: 3px solid $blue;
  padding-bottom: 30px;
}

.business-container {
  background-color: $blue;
  position: relative;

  &:hover {
    h2 {
      color: black;
    }

    .white-btn {
      border: 1px solid $yellow;
      background-color: $yellow;
      color: black;
    }

    .business {
      opacity: 1;
    }
  }
}

.sub-heading {
  text-align: center;
  @include font-size(1.4);
  margin: 30px;
}

.business-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;

  h2 {
    margin-top: 40px;
    color: white;
    transition: all 0.2s ease-in-out;
  }

  .white-btn {
    margin-bottom: 40px;
  }
}

.business {
  height: 200px;
  margin-bottom: 30px;
  opacity: 0.5;
  width: 100%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

.videos-container {
  padding-top: 70px;
  padding-bottom: 50px;
  background-color: $grey-content;

  .container {
    padding-right: 30px;
    padding-left: 30px;
  }

  .col-12 {
    background-color: $blue;
    display: block;
    padding: 0;
    position: relative;

    &:hover .arrow {
      background: url("../img/arrow2.png");
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  .video {
    height: 200px;
    display: block;
    width: 100%;
    background-size: cover;
    position: relative;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    // &:before {
    //   content: "";
    //   height: 100%;
    //   width: 100%;
    //   position: absolute;
    //   background-color: $blue;
    //   opacity: 0.3;
    //   transition: all 0.2s ease-in-out;
    // }
    // &:hover:before {
    //   opacity: 0;
    // }
  }
}
.video__arrow {
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: url("../img/arrow.png");
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: url("../img/arrow2.png");
    background-repeat: no-repeat;
    background-position: center center;
  }
}
.slide-content {
  height: 150px;
  position: absolute;
  right: 0;
  width: 100%;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  img {
    max-width: 25vw !important;
    padding: 20px 10px;
    max-height: 145px;
    width: auto;
    object-fit: contain;
  }
  p {
    padding: 0px 40px;
    @include font-size(2.5); 
    font-weight: 500;
  }
  a {
    margin: 45px 10px;
  }
  @media screen and (max-width: 850px) {
    a {
      margin: 0 10px;
    }
    p {
     font-size: 16px; 
    }
    bottom: -132px;
  }
  @media screen and (max-width: 550px) {
    img {
      max-width: 200px !important;
      padding: 20px 10px;
    }
    p {
      padding: 0px 10px;
      text-align: center;
    }
    a {
      left: 0% !important;
      transform: translateX(0) !important;
    }
    bottom: -174px;
  }
}

.our-brands {
  position: relative;
  padding-bottom: 50px;

  &:before {
    position: absolute;
    content: "";
    width: 160px;
    height: 1px;
    background-color: $blue;
    bottom: 0;
    left: 50%;
    transform: translateX(-80px);
  }
}

.brands-container {
  flex-wrap: wrap;

  img {
    max-width: 190px;
    margin: 0 auto !important;
    height: auto;
    max-height: 70px;
    margin: 10px 25px;
  }
}

.our-clients {
  padding-bottom: 50px;
}

iframe {
  height: 100%;
  width: 100%;
}
.certificates {
  img {
    margin: 10px 20px;
    max-width: 200px;
  }
}

.wpml-ls-item-cs {
  pointer-events: none;
}

.wpml-ls-item-en {
  // pointer-events: none;
}

@media screen and (max-width: 1200px) {
  .slide {
    a {
      top: 70%;
    }
  }
}
@media screen and (max-width: 768px) {
  .slide {
    a {
      top: 90%;
      right: 50%;
      transform: translateX(50%);
    }
  }
  .main-h1-container {
    padding: 50px 0 10px;
  }
}
@media screen and (max-width: 576px) {
  .main-descr-section .main-descr {
    padding: 15px;
  }
  .slide {
    a {
      text-align: center;
      top: 85%;
    }
  }
  .main-h1-container {
    padding: 70px 0 10px;
  }
}
