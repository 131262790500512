.singleproduct-section {
  position: relative;
  margin-top: 50px;
  .row:first-child {
    padding-bottom: 50px;
  }
  .row:nth-child(2) {
    border-top: 1px solid black;
    padding-bottom: 30px;
    padding-top: 30px;
  }
  .col-lg-4 {
    padding: 15px 15px;
    display: block;
    position: relative;
    .arrow {
      position: absolute;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: url("../img/arrow.png");
      background-repeat: no-repeat;
      background-position: center center;
      transition: all 0.3s ease-in-out;
    }
  }
  .video {
    height: 250px;
    display: block;
    width: 100%;
    margin-bottom: 20px;
    background-size: cover;
    position: relative;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &:hover .arrow {
      background: url("../img/arrow2.png");
      background-repeat: no-repeat;
      background-position: center center;
    }
    // &:before {
    //   content: "";
    //   height: 100%;
    //   width: 100%;
    //   position: absolute;
    //   background-color: $blue;
    //   opacity: 0.3;
    //   transition: all 0.2s ease-in-out;
    // }
    // &:hover:before {
    //   opacity: 0;
    // }
  }
}
.singleproduct-content {
  ul {
    span {
      font-family: $glob-font !important;
      font-size: 16px !important;
    }
  }
  p {
    // line-height: 25px;
    text-align: justify;
  }
  span {
    font-family: $glob-font !important;
    font-size: 16px !important;
  }
} 
.thumbnail {
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.singleproduct-logo {
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
  max-width: 70%;
}
.system {
  font-weight: 700;
  padding-top: 20px;
}
.file-desc {
  display: inline-block;
  margin: 5px 0;
}
.link {
  color: $blue;
  text-decoration: underline;
  font-weight: 600;
}
.file-desc {
  @include font-size(1.4);
}
.download-container {
  margin-top: 20px;
  padding: 15px;
  background-color: $blue;
  color: $white;
  .link {
    color: $white;
    display: inline;
    display: inline-block;
    @include font-size(1.3);
    padding: 10px;
  }
  .to_download {
    display: inline-block;
    padding: 0 10px;
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .singleproduct-section {
    .yellow-links-container {
      top: -80px;
    }
  }
  .singleproduct-content {
    margin-top: 30px;
  }
}
