.offer__image {
  width: 100%;
  object-fit: cover;
}
.offer__name {
  height: 50px;
  font-weight: 500;
  @include font-size(1.8);
}
.page-template-page-offer .offer__name {
  text-transform: uppercase;
}
.products {
  .yellow-btn {
    max-height: 42px;
  }
}
.for_downloads {
  .container {
    border-top: 1px solid #000;
    padding: 30px 0;
  }
  p {
    display: inline-block;
    font-weight: 700;
    padding-right: 20px;
  }
  a {
    color: $blue;
    display: inline-block;
    padding: 0 10px;
    text-decoration: underline;
    font-weight: 700;
    &:hover {
      text-decoration: none;
    }
  }
}
.img-container {
  transition: $transition;
  max-height: 150px;
  overflow: hidden;
  width: 100%;
  // background-color: $blue;
  img {
    opacity: 1;
    margin-bottom: -5px;
  }
  &:hover {
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.5);
    img {
      opacity: 1;
    }
  }
}
.cat-photo-container {
  height: 150px;
  overflow: hidden;
  transition: $transition;
  &:hover {
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.5);
  }
}
