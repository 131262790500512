/* Colors */
$grey-menu: #f5f5f5;
$grey-content: #f1f1f1;
$yellow: #f2e302;
$yellow-light: #fcf5ad;
$grey-line: #959595;
$grey-lang-hover: #e1e1e1;
$blue: #004c9a;
$grey-footer: #252525;
$grey-title: #373737;
$grey-input: #ebebeb;
$grey-bread: #7d7d7d;


/* Shadow presets */
$shadow-wide-light: 0 0 20px 3px rgba(0, 0, 0, 0.3);

/* Font used across the document */
$glob-font: "Roboto", sans-serif;

/* Transition time for news list */
$transition: all 0.2s ease-in-out;
