.mailpoet_text_label {
  display: none !important;
}

#mailpoet_form_2 .mailpoet_validate_success {
  color: $yellow !important;
  position: absolute;
}

.mailpoet_paragraph {
  display: inline-block;
}

.mailpoet_paragraph {
  display: inline-block;
}

#mailpoet_form_2 {
  display: flex;
  align-items: center;
}

#mailpoet_form_2 .mailpoet_form {
  display: flex;
}

.parsley-custom-error-message {
  color: $yellow !important;
  position: absolute;
}

.mailpoet_text {
  border: none;
  width: 500px !important;
  padding: 17px;
}

input.parsley-error {
  border: none !important;
}

.mailpoet_submit {
  background-color: $yellow;
  margin-left: -5px;
  padding: 15px 30px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 0.2s ease-in-out;

  &:hover {
    border: 2px solid $yellow;
    background-color: transparent;
    color: $yellow;
  }
}

@media screen and (max-width: 1200px) {
  .mailpoet_text {
    width: 250px !important;
  }
}

@media screen and (max-width: 991px) {
  #mailpoet_form_2 {
    margin-top: 15px;
  }
}

@media screen and (max-width: 430px) {
  .mailpoet_submit {
    margin-left: 0px;
  }
  #mailpoet_form_2 {
    .mailpoet_form {
      flex-direction: column;
      flex-wrap: wrap;
    }
  }
  .mailpoet_submit {
    // margin-top: 15px;
  }
}
