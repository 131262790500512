.products {
  position: relative;
  .yellow-btn {
    margin-top: 0px;
    margin-bottom: 40px;
    &:hover {
      background-color: white !important;
    }
  }
}
.products__img {
  height: 150px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  transition: all .2s ease-in-out;
  &:hover {
    box-shadow: 0px 5px 7px rgba(0,0,0,.5);
  }
}

.products__title {
  @include font-size(2);
  height: 45px;
  margin-top: 20px;
  padding: 0;
}

.products__date {
  @include font-size(2);
  margin-top: 5px;
  font-weight: 700;
}

@media screen and (max-width: 991px) {
  .products__title {
    margin-top: 20px;
  }
}




