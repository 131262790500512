.house__container {
  position: absolute;
  width: 70%;
  left: 15%;
  position: relative;
  border-radius: 50%;
  margin-bottom: 70px;
  background-color: #fff;
  transition: all 0.7s ease;
}
.house {
  max-width: 100%;
  opacity: 1;
  transition: all 0.7s ease;
}
area {
  background-color: red;
  position: relative;
  z-index: 100;
}
.house__circle {
  width: 5%;
  height: 5.5%;
  position: absolute;
  border-radius: 50%;
  background-color: rgba(242, 227, 2, 0.7);
  z-index: 0;
  cursor: pointer;
  &:hover {
    background-color: rgba(242, 227, 2, 1);
    transition: all 0.2s ease;
  }
  span {
    position: absolute;
    z-index: 1;
    font-weight: bold;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}
.house__container div:first-of-type {
  top: 50.5%;
  left: 47%;
}
.house__container div:nth-of-type(3) {
  top: 40%;
  left: 47%;
}
.house__container div:nth-of-type(5) {
  top: 24%;
  left: 66%;
}
.house__container div:nth-of-type(7) {
  top: 47%;
  left: 84%;
}
.house__container div:nth-of-type(9) {
  top: 41%;
  left: 92%;
}
.house__container div:nth-of-type(11) {
  top: 48%;
  left: 74%;
}
.house__container div:nth-of-type(13) {
  top: 68%;
  left: 70%;
}
.house__container div:nth-of-type(15) {
  top: 73%;
  left: 71%;
}
.house__container div:nth-of-type(17) {
  top: 72%;
  left: 66%;
}
.house__container div:nth-of-type(19) {
  top: 65%;
  left: 51%;
}
.house__container div:nth-of-type(21) {
  top: 43%;
  left: 38%;
}
.house__container div:nth-of-type(23) {
  top: 54%;
  left: 35%;
}
.house__container div:nth-of-type(25) {
  top: 66%;
  left: 25%;
}
.house__container div:nth-of-type(27) {
  top: 56%;
  left: 22%;
}
.house__container div:nth-of-type(29) {
  top: 53%;
  left: 3%;
}
.house__container div:nth-of-type(31) {
  top: 51%;
  left: 24%;
}
.house__img {
  width: 5%;
  height: 5.5%;
  visibility: hidden;
  position: absolute;
  transition: all 0.7s ease;
  z-index: 50;
  border-radius: 50%;
  p {
    font-size: 3px;
    text-align: center;
    top: -5px;
    position: relative;
    width: 120%;
    right: 10%;
    line-height: 4px !important;
    background-color: white;
    padding: 3px 4px;
    border-radius: 70px;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.4);
  }
  img {
    max-width: 20px;
    position: relative;
    top: 0.5px;
    padding: 0 0.7px;
  }
}
.house__container div:nth-of-type(2) {
  top: 50.5%;
  left: 47%;
  background: url("../img/window.png");
  background-size: cover;
}
.house__container div:nth-of-type(4) {
  top: 40%;
  left: 47%;
  background: url("../img/img_2.png");
  background-size: cover;
  img {
    top: 1px;
  }
  p {
    top: -10px;
  }
}
.house__container div:nth-of-type(6) {
  top: 24%;
  left: 66%;
  background: url("../img/img_3.png");
  background-size: cover;
  img {
    top: 1px;
  }
}
.house__container div:nth-of-type(8) {
  top: 47%;
  left: 84%;
  background: url("../img/img_4.png");
  background-size: cover;
  p {
    top: -15px;
  }
}
.house__container div:nth-of-type(10) {
  top: 41%;
  left: 92%;
  background: url("../img/img_5.png");
  background-size: cover;
  img {
    top: 1px;
  }
}
.house__container div:nth-of-type(12) {
  top: 48%;
  left: 74%;
  background: url("../img/img_6.png");
  background-size: cover;
  img {
    top: 1.5px;
  }
}
.house__container div:nth-of-type(14) {
  top: 68%;
  left: 70%;
  background: url("../img/img_7.png");
  background-size: cover;
}
.house__container div:nth-of-type(16) {
  top: 73%;
  left: 71%;
  background: url("../img/img_8.png");
  background-size: cover;
}
.house__container div:nth-of-type(18) {
  top: 72%;
  left: 66%;
  background: url("../img/img_9.png");
  background-size: cover;
}
.house__container div:nth-of-type(20) {
  top: 65%;
  left: 51%;
  background: url("../img/img_10.png");
  background-size: cover;
}
.house__container div:nth-of-type(22) {
  top: 43%;
  left: 38%;
  background: url("../img/img_11.png");
  background-size: cover;
  img {
    top: 1.5px;
  }
  p {
    width: 140%;
    right: 20%;
  }
}
.house__container div:nth-of-type(24) {
  top: 54%;
  left: 35%;
  background: url("../img/img_12.png");
  background-size: cover;
}
.house__container div:nth-of-type(26) {
  top: 66%;
  left: 25%;
  background: url("../img/img_13.png");
  background-size: cover;
  p {
    width: 140%;
    right: 20%;
  }
}
.house__container div:nth-of-type(28) {
  top: 56%;
  left: 22%;
  background: url("../img/img_16.png");
  background-size: cover;
  img {
    top: 1.5px;
  }
  p {
    top: -15px;
  }
}
.house__container div:nth-of-type(30) {
  top: 53%;
  left: 3%;
  background: url("../img/img_15.png");
  background-size: cover;
  p {
    top: -15px;
    width: 140%;
    right: 20%;
  }
}
.house__container div:nth-of-type(32) {
  top: 51%;
  left: 24%;
  background: url("../img/img_14.png");
  background-size: cover;
  img {
    top: 1.5px;
  }
}
.show-circle {
  visibility: visible !important;
  transform: scale(5);
  transition: all 0.5s ease;
  // animation: appear 2s ease forwards;
}
.house-transparent {
  opacity: 0.5;
  transition: all 0.7s ease;
}
.black {
  background-color: black;
  transition: all 0.7s ease;
}
.cursor {
  cursor: pointer;
}
.scale {
  transform: scale(0.5) !important;
  transition: all 0.7s ease;
}
.house__link {
  color: $blue;
  &:hover {
    color: black;
  }
}

@media screen and (max-width: 1450px) {
  .house__container {
    width: 80%;
    left: 10%;
  }
}
@media screen and (max-width: 1200px) {
  .show-circle {
    transform: scale(8);
  }
  .house__img {
    p {
      font-size: 2px;
      line-height: 2.5px !important;
      top: -5px;
      border-radius: 3px;
      width: 140%;
      right: 20%;
    }
    &.img_15 {
      p {
        top: -12px;
      }
    }
    &.img_14 {
      p {
        top: -8px;
      }
    }
    img {
      max-width: 10px;
      position: relative;
      top: 0px;
      padding: 0 0.7px;
    }
  }
  .img_15 {
    left: 25%;
  }
  .img_4 {
    left: 70%;
  }
  .img_5 {
    left: 70%;
  }
 
}
@media screen and (max-width: 768px) {
  .show-circle {
    transform: scale(11);
  }
  .house__img {
    p {
      font-size: 1px;
      line-height: 1.5px !important;
      top: -8px;
      border-radius: 5px;
      width: 140%;
      right: 20%;
      padding: 3px 3px 2px;
    }
    img {
      max-width: 10px;
      position: relative;
      top: 0px;
      padding: 0 0.7px;
    }
  }
  .house__container {
    width: 90%;
    left: 5%;
  }
}

