.page-template-page-files {
  .subpage-content {
    text-align: left;
  }
  .files-container {
    text-align: left;
  }
}

.files-container {
  text-align: justify;
  margin-bottom: 60px;
  .row {
    padding: 0 110px;
  }
}
.file__category {
  color: $grey-title;
  margin-left: 10px;
  margin-top: 50px;
  &:first-child {
    margin-top: 0;
  }
}
.file__name {
  color: $blue;
  text-decoration: underline;
  margin: 0 10px;
  margin-bottom: 10px;
  display: inline-block;
}

@media screen and (max-width: 991px) {
  .files-container {
    .row {
      padding: 0px;
    }
  }
}

@media screen and (max-width: 768px) {
  .files-container {
    .yellow-links-container {
      // top: -160px;
    }
  }
}