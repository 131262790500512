.wpcf7-form {
  margin-top: 20px;
  width: 100% !important;
  display: inline-block;
  .col-md-7 {
    padding-left: 0;
  }
  .col-md-5 {
    padding-right: 10px;
  }
  input {
    height: 45px;
    padding-left: 15px;
  }
  input:first-child {
    margin-bottom: 10px;
  }
  textarea, input {
    width: 100%;
    display: inline-block;
    background-color: $grey-input;
    border: none;
  }
  textarea {
    height: 100px !important;
    padding: 15px;
  }
  .wpcf7-submit {
    width: 100px;
    height: 35px;
    background-color: $blue;
    color: $white;
    float: right;
    cursor: pointer;
    transition: all .2s ease-in-out;
    &:hover {
      background-color: $white;
      border: 1px solid $blue;
      color: $blue;
    }
  }
  input[type=submit] {
    padding-left: 0px;
    // margin-top: 0px !important;
  }
  div.wpcf7-validation-errors {
    border: none !important;
    margin-left: 0 !important;
    padding-left: 0 !important;
    color: $blue !important;
  }

  .div.wpcf7-validation-errors {
    color: $blue !important;
  }
  span.wpcf7-not-valid-tip {
    color: $blue !important;
  }
}

@media screen and (max-width: 768px) {
  .wpcf7-form {
    .col-md-7 {
      padding-left: 15px;
    }
    .col-md-5 {
      padding-right: 15px;
    }
  }
  .wpcf7-form-control-wrap {
    padding-top: 10px !important;
  }
  .wpcf7-form-control.wpcf7-submit {
    margin-top: 10px !important;
    display: block !important;
  }
}
div.wpcf7 .ajax-loader{
  position: absolute !important;
}