.legend {
  position: relative;
  width: 17vw;
  @include font-size(1.3);
  text-align: left;
}
.legend__item {
  padding: 10px;
  width: 100%;
  display: flex;
  position: absoluterelative;
  box-shadow: 0 10px 40px -25px rgba(0,0,0,.7);
  align-items: center;
  margin-bottom: 20px;
}
.legend__number {
  position: relative;
  padding: 9px;
  display: inline-block;
  border-radius: 50%;
  background-color: #f2e302;
}
.first-numbers {
  padding: 9px 13px;
}
.legend__text {
  margin-bottom: 0;
  padding-left: 8px;
  font-size: 12px !important;
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  img {
    max-width: 80px;
    position: relative;
    top: 7px;
    padding: 5px;
  }
}
// .legend__right {
//   position: absolute;
//   left: calc(80vw + 15px);
//   top: 0;
// }
