.owl-carousel {
  position: relative;
  .owl-stage {
    display: flex;
    align-items: center;
  }
  .owl-item {
    img {
      height: auto;
    }
  }
}
.main-carousel {
  .owl-stage-outer {
    overflow: visible !important;
  }
}
.brands-carousel {
  .owl-item {
    img {
      width: auto;
    }
  }
  .owl-stage-outer {
    margin-left: -10px;
  }
}
.our-brands.owl-stage-outer, .our-clients.owl-stage-outer{
  display: flex;
  flex: 0 1 auto;
  height: 100%; /* fix IE11 */
  align-items: center;
}
.owl-nav {
  z-index: -1;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  top: 50%;
  transform: translateY(-50%);

  .owl-prev {
    background-image: url("../img/arrow_left.png") !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    height: 30px;
    width: 30px;
    margin-left: -60px;
    transition: $transition;
    &:hover {
      margin-left: -65px;
    }
  }

  .owl-next {
    background-image: url("../img/arrow_right.png") !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    height: 30px;
    width: 30px;
    margin-right: -60px;
    transition: $transition;
    &:hover {
      margin-right: -65px;
    }
  }
}

@media screen and (max-width: 576px) {
  .owl-nav {
    position: relative;
    margin-left: 35%;
    width: 30%;
    top: 0px;
    transform: none;
    padding: 10px 0;

    .owl-prev {
      margin-left: 0;
    }

    .owl-next {
      margin-right: 0;
    }
  }
}
