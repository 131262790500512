.product-carousel {
  list-style: none;
  padding-left: 0;
  .owl-nav {
    .owl-prev {
      margin-left: -50px;
      &:hover {
        margin-left: -55px;
      }
    }
    .owl-next {
      margin-right: -50px;
      &:hover {
        margin-right: -55px;
      }
    }
  }
}
.product-carousel.news-slider {
  padding: 30px 0;
  border-top: 1px solid #000;
  padding-left: 0;
  .owl-next {
    margin-right: -50px;
    &:hover {
      margin-right: -55px;
    }
  }
}
