.news {
  position: relative;
  .row {
    padding: 0 100px;
  }
  .yellow-btn {
    text-transform: uppercase;
    margin-top: 0;
    &:hover {
      background-color: $white;
    }
  }
}

.news__img {
  height: 215px;
  background-size: cover;
  background-position: center;
  width: 100%;
  transition: all .2s ease-in-out;
  &:hover {
    box-shadow: 0px 5px 7px rgba(0,0,0,.5);
  }
}

.news__title {
  @include font-size(2);
  margin: 0;
  padding: 0;
}

.news__date {
  @include font-size(2);
  margin-top: 5px;
  font-weight: 700;
}

.news__excerpt + p{
  line-height: 25px;
}

@media screen and (max-width: 991px) {
  .news__title {
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  .news {
    .row {
      padding: 0;
    }
  } 
}



