// -----------------------------------------------------------------------------
// This file contains styles for layout related helper functions
// -----------------------------------------------------------------------------

header {
  .menu-item-has-children {
    &:hover {
      background-color: $yellow;
    }
    .menu-item-has-children:hover {
      background-color: $yellow-light;
    }
    position: relative;
    .sub-menu {
      display: none;
      box-shadow: 0 5px 7px rgba(0, 0, 0, 0.3);
      position: absolute;
      z-index: 10;
      list-style: none;
      background-color: #fff;
      padding-left: 0;
      width: 400px;
      .sub-menu {
        left: 400px;
        top: 0;
        @media screen and (max-width: 991px) {
          display: none !important;
        }
        li {
          a {
            text-transform: none !important;
          }
        }
      }
      a:hover {
        background-color: $grey-lang-hover !important;
      }
    }
  }
  .current_page_item {
    background-color: $yellow;
  }

  .current_page_ancestor {
    background-color: $yellow;
  }
  .container {
    position: relative;
  }

  .navbar-brand {
    position: absolute;
    z-index: 10;
    top: 10px;
    img {
      height: 100px;
      width: auto;
    }
  }

  .navbar-nav {
    list-style: none;
  }

  #menu-primary-menu,
  #menu-top-menu {
    margin-left: auto;
    li {
      margin-right: 2px;
    }
    .current_page_item {
      background-color: $yellow;
      padding: 10px 0;
    }
  }

  #top-menu {
    width: 100%;
    height: 120px;

    .navbar-nav {
      margin-right: 20px;
    }

    a {
      text-transform: uppercase;
      @include font-size(1.6);
      padding: 10px;
      font-weight: 500;

      &:hover {
        background-color: $grey-lang-hover;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .wpml-ls-legacy-list-horizontal {
    padding-right: 0;
  }

  .wpml-ls-item {
    margin-right: 2px !important;
  }

  .wpml-ls-statics-shortcode_actions {
    .wpml-ls-current-language .wpml-ls-link {
      font-weight: 700 !important;
      background-color: $grey-lang-hover;
      transition: all 0.3s ease-in-out;
    }

    a {
      display: block;
      padding: 7px !important;
      font-weight: 400 !important;
      transition: all 0.3s ease-in-out;

      &:hover {
        // font-weight: 700 !important;
        background-color: $grey-lang-hover;
      }
    }
  }

  .hamburger {
    margin-top: 10px;
  }

  #bottom-menu {
    background-color: $grey-menu;
    width: 100%;
    position: relative;

    &:after {
      content: "";
      width: 200%;
      position: absolute;
      height: 100%;
      background-color: $grey-menu;
      z-index: -2;
      left: -50%;
      top: 0;
    }

    li {
      margin: 0 2px;

      a {
        @include font-size(1.7);
        padding: 15px 25px;
        display: block;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: $yellow;
        }
      }
      li {
        margin: 0;
        a {
          @include font-size(1.6);
        }
        li {
          a {
            @include font-size(1.5);
            padding: 15px 20px;
          }
        }
        &.menu-item-has-children > a {
          position: relative;
          &:after {
            content: "";
            display: block;
            height: 10px;
            width: 10px;
            border-top: 3px solid #233a8f;
            border-right: 3px solid #233a8f;
            border-radius: 3px;
            right: 15px;
            top: 50%;
            transform: rotate(45deg) translateY(-50%);
            position: absolute;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    #bottom-menu {
      li {
        a {
          padding: 15px;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    padding-bottom: 20px;
    #mainNav {
      background-color: $grey-menu;
      margin-top: 25px;
      max-width: 100%;
    }
    #top-menu {
      flex-direction: column;
    }

    .navbar-brand img {
      width: 150px;
      height: auto;
    }

    .hamburger {
      margin-top: 20px;
    }
    #menu-top-bottom-menu {
      width: 280px;
      li {
        margin: 3px 0;
      }
    }
    #bottom-menu {
      padding-bottom: 20px;
    }

    #bottom-menu:after {
      background-color: transparent;
    }

    .sub-menu {
      position: relative !important;
      li {
        display: block;
        a {
          text-transform: none !important;
        }
      }
    }

    #menu-primary-menu {
      width: 300px;
      padding-top: 20px;
      margin-left: 20px;
      li {
        padding-bottom: 10px;
      }
    }
  }
}
