.popup-section {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  z-index: 100000;
  .popup-container {
    transform: translate(-50%, -50%);
    max-width: 50vw;
    max-height: 90vh;
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    img {
      box-shadow: 0 10px 150px -20px black;
      top: 50vh;
      left: 50vw;
      max-width: 50vw;
    }
  }
  .popup-close {
    position: absolute;
    font-size: 40px;
    padding: 0 5px;
    background-color: $yellow;
    border: none;
    cursor: pointer;
    box-shadow: 0 10px 35px -5px rgba(black, 0.7);
    border: 2px solid $yellow;
    top: 0;
    left: calc(100% - 48px);
    transition: background-color 0.2s ease;
    &:hover {
      background-color: transparent;
    }
  }
}

@media screen and (max-width: 1200px) {
  .popup-section {
    .popup-container {
      max-width: 70vw;
      img {
        max-width: 70vw;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .popup-section {
    .popup-container {
      max-width: 90vw;
      img {
        max-width: 90vw;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .popup-section {
    .popup-container {
      max-width: 95vw;
      img {
        max-width: 95vw;
      }
    }
    .popup-close {
      font-size: 30px;
      padding: 0 3px;
      left: calc(100% - 28px);
    }
  }
}
