.hd-search {
  margin-left: 10px;
  .search-form {
    display: flex;
    margin-top: 15px;
  }
  .screen-reader-text {
    position: absolute !important;
    left: -999em;
  }
  .search-submit {
    background: url("../img/lupa.png") no-repeat center center;
    background-color: $grey-menu;
    height: 32px;
    color: $white;
    cursor: pointer;
    padding: 0px 15px;
    border: 1px solid $black;
    border-left: 1px solid transparent;
    transition: $transition;
    margin-left: -1px;
    svg {
      height: 17px;
      width: auto;
      transition: $transition;
      fill: $white;
    }
    &:hover {
      // background-color: $white;
      svg {
        fill: $black;
      }
    }
  }
  .search-field {
    padding: 6px 10px;
    border: 1px solid $black;
    background-color: $grey-menu;
  }
}

@media screen and (min-width: 992px) {
  .hd-search {
    .search-form {
      margin-top: 0;
    }
  }
}

