.contact-content {
  .contact-top-img {
    max-width: 100%;
  }
  a {
    transition: $transition;
  }
  a:active, a:hover, a:focus {
    color: $blue;
    font-weight: 600;
  }
  .blue-title-container {
    img {
      display: block;
      margin-right: 10px;
    }
    .office {
      color: $blue;
      font-weight: 700;
      display: inline-block;
    }
  }
  .contact-info-row {
    position: relative;
    &:before {
      position: absolute;
      content: '';
      width: 133px;
      height: 3px;
      left: 15px;
      background-color: $yellow;
      bottom: -20px;
    }
    margin-top: 40px;
    h3 {
      font-weight: 600;
      margin-bottom: 0;
    }
    .info-box {
      margin-bottom: 40px;
      position: relative;
      p {
        line-height: 25px;
      }
      .hours {
        margin: 0;
      }
    }
    .info-box:nth-child(3):before {
      position: absolute;
      content: '';
      height: 170px;
      width: 1px;
      background-color: $grey-line;
      top: 50%;
      transform: translateY(-50%);
      left: -10px;
    }
    .info-box-secertary {
      margin-top: 65px;
      ul {
        list-style: none;
        padding-left: 0;
        li {
          padding: 5px 30px;
        }
        li:first-child {
          background: url('../img/telefon_small.png');
          background-repeat: no-repeat;
          background-position: left center;
        }
        li:nth-child(2) {
          background: url('../img/fax_small.png');
          background-repeat: no-repeat;
          background-position: left center;
        }
        li:nth-child(3) {
          background: url('../img/mail_small.png');
          background-repeat: no-repeat;
          background-position: left center;
        }
      }
    }
  }
  .info-box-service {
    &:before {
      background-color: transparent !important;
    }
    ul {
      list-style: none;
      padding-left: 0;
      li {
        padding: 5px 30px;
      }
      li:first-child {
        background: url('../img/telefon_small.png');
        background-repeat: no-repeat;
        background-position: left center;
      }
      li:nth-child(2) {
        background: url('../img/mail_small.png');
        background-repeat: no-repeat;
        background-position: left center;
      }
    }
  }
  .contact-info-row-last {
    margin-bottom: 50px;
  }
}
#map {
  width: 100%;
  height: 400px;
  position: relative;
}
.leaflet-control-attribution.leaflet-control {
  display: none;
}
.leaflet-attribution {
  padding: 2px 10px;
  background-color: rgba(255,255,255,.9);
  position: absolute;
  z-index: 1000;
  bottom: 5px;
  right: 5px;
}
.leaflet-control-container {
  position: absolute;
  right: 55px;
}

#mapimg {
  align-self: center;
}

.region {
  padding: 5px;
  // position: absolute;
  z-index: 10;
  margin-top: 30px;
  // top: 40%;
  // right: 15%;
  p {
    strong {
      // line-height: 15px !important;
      // font-size: 13px;
    }
  }
  ul {
    margin-top: 0px;
    li:first-child {
      background: url('../img/telefon_small.png');
      background-repeat: no-repeat;
      background-position: left center;
    }
    li:nth-child(2) {
      background: url('../img/mail_small.png');
      background-repeat: no-repeat;
      background-position: left center;
    }
  }
}

.yellow {
  color: $yellow;
}

.grey {
  background-color: rgba($blue, .3);
  border-radius: 10px;
}

.angelika {
  padding-top: 70px;
  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .info-box-secertary {
    margin-top: 0 !important;
  }
}

