/**
 * Basic typography style for copy text
 */

body {
  color: $black;
  font-family: $glob-font;
}

a {
  color: black;
  text-decoration: none;
  &:visited,
  &:hover {
    text-decoration: none;
  }
}

.main-title {
  color: $grey-title;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  display: inline-block;
  padding-bottom: 15px;
  margin-left: 50%;
  transform: translateX(-50%);
  font-weight: 600;
  margin-top: 50px;
  margin-bottom: 60px;
  @include font-size(2.5);
  &:before {
    content: "";
    height: 3px;
    bottom: 0;
    left: 0;
    position: absolute;
    // left: 50%;
    // transform: translateX(-65px);
    width: 100%;
    background-color: $yellow;
  }
}

.category-main {
  .main-title {
    text-transform: none;
  }
}
