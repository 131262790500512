.footer-top {
  padding: 50px 0;
  background-color: $blue;
  color: white;

  p {
    margin-bottom: 5px;
    margin-top: 0;
  }

  h3 {
    text-transform: uppercase;
    margin: 0;
  }
}

.footer-bottom {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: $grey-footer;
  color: white;

  .footer-menu {
    .sub-menu {
      display: none;
    }
    margin-left: -10px;
    a {
      color: white;
      text-transform: uppercase;
      display: block;
      padding: 10px 10px;
      transition: all 0.3s ease;
      &:hover {
        color: $yellow;
      }
    }
  }

  .ms {
    padding: 20px 0;
  }

  .seo {
    @include font-size(1.2);
    text-transform: uppercase;
    color: white;
  }

  span {
    text-transform: uppercase;
    @include font-size(1.2);
  }
}
