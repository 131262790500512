// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------
html {
	overflow-x: hidden;
	height: 100%;
}
body {
	height: 100%;
	background: #fff;
	overflow-x: hidden;
}

* {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.alignright {
	float: right !important;
	padding-bottom: 10px;
	padding-left: 15px;
}
.alignleft {
	float: left !important;
	padding-right: 15px;
	padding-bottom: 10px;
}

