.yellow-btn {
  border: none;
  background-color: $yellow;
  margin-top: 20px;
  padding: 10px 15px;
  display: inline-block;
  transition: all .3s ease-in-out;
  border: 2px solid transparent;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.7px;

  &:hover {
    border: 2px solid $yellow;
    background-color: $grey-content;
  }
}