.breadcrumbs {
  color: $grey-bread;
  margin-bottom: 40px;
  .col-12 {
    background-color: $grey-menu;
  }
  a {
    text-transform: uppercase;
    @include font-size(1.1);
    display: inline-block;
    padding: 20px 10px;
    color: $grey-bread;
  }
}

.breadcrumb_last {
  @include font-size(1.1);
  text-transform: uppercase;
  font-weight: 700;
  padding-left: 10px;
}

#breadcrumbs {
  margin: 0;
}
