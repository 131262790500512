.our-brands.subpage {
  padding-bottom: 10px;
  &:before {
    background-color: transparent;
  }
}
.subpage-content {
  p {
    line-height: 25px;
    margin-top: 0;
  }
}
