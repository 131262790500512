.yellow-links-container {
  list-style: none;
  position: fixed;
  z-index: 100;
  right: 0;
  top: 45vh;
  @media screen and (max-width: 576px) {
    top: 60vh
  }
}

.yellow-links {
  position: relative;
  list-style: none;
  span {
    display: none;
  }
}

.yellow-link {
  &:nth-child(2) {
    margin-top: 55px;
  }

  &:nth-child(3) {
    margin-top: 110px;
  }

  position: absolute;
  z-index: 100;
  right: 0;

  &:hover > a {
    width: 100px;
  }
  &:nth-child(2) span {
    position: absolute;
    opacity: 0;
    display: flex;
    white-space: nowrap;
    transform: translateX(150px);
    transition: all .6s ease-in-out;
    font-weight: 600;
  }
  &:hover{
    a {
      width: 200px;
    }
    span {
      opacity: 1;
      transform: translateX(50px);
    }
  }

  > a {
    img {
      width: 35px;
      height: auto;
    }

    display: flex;
    align-items: center;
    background-color: $yellow;
    padding: 5px 30px 5px 9px;
    width: 74px;
    height: 50px;
    transition: all .4s ease-in-out;
  }
}