.subpage-content {
  position: relative;
  text-align: justify;
  &__photos img {
    margin-bottom: 30px;
    max-width: 100%;
  }
  .yellow-btn {
    margin-bottom: 40px;
    &:hover {
      background-color: white !important;
    }
  }
}

.brand-img-container {
  height: 150px;
  overflow: hidden;
  a {
    transition: all 0.2s ease;
    display: block;
    height: 140px;
    &:hover {
      box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.5);
    }
  }
}

.brand-photo {
  width: 100%;
  height: 150px;
  padding-bottom: 10px;
  object-fit: contain;
  object-position: center;
}
.wp-block-gallery {
  width: 50%;
  margin: 0 auto;
}
// .categories-container {
//   .col-md-6 {
//     padding-bottom: 50px;
//   }
//   .yellow-btn {
//     position: absolute;
//     bottom: -40px;
//   }

// }
.brand-desc {
  a {
    strong {
      color: $blue;
      text-decoration: underline;
    }
  }
  strong {
    a {
      color: $blue;
      text-decoration: underline;
    }
  }
}
