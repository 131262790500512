.white-btn {
  border: 1px solid white;
  background-color: transparent;
  margin-top: 0px;
  color: white;
  padding: 15px;
  display: inline-block;
  transition: all .3s ease-in-out;
  font-weight: 400;
  display: inline-block;

  &:hover {
    border: 1px solid $yellow;
    background-color: $yellow;
    color: black;
  }
}