.navbar-toggler {
  outline: none;
  padding-right: 0;
}

.navbar {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.navbar-nav {
  margin: 0;
}
